import React from "react";
import { graphql } from 'gatsby';

import SEO from "../../components/seo";
import PageWrapper from "../../components/_molecules/page-wrapper";
import Card from "../../components/card";
import ListingItem from "../../components/_molecules/listing-item";
import Pagination from "../../components/pagination";
import PageHero from "../../components/_organisms/page-hero";

import {
    FeaturedPosts,
    FeaturedPostWrapper,
    PostDescription,
    SubHeader,
    PostWrapper,
    LandingWrapper
} from "./styled";

const Blog = ({ data, pageContext }) => {
    const { currentPage, numPages } = pageContext;
    const { blogLanding, latestPosts, mediumFeed } = data;
    
    const validMediumPosts =
        mediumFeed.edges
            .filter(mediumPost =>
                mediumPost.node.date <= latestPosts.edges[0].node.publishDate
                && mediumPost.node.date >= latestPosts.edges[latestPosts.edges.length - 1].node.publishDate
            )

    const allPosts = latestPosts.edges.concat(...validMediumPosts.map(mediumPost => ({
        node: {
            title: mediumPost.node.title,
            slug:  mediumPost.node.link,
            publishDate: mediumPost.node.date,
            heroImages: [ { src: mediumPost.node.thumbnail } ],
            source: 'medium',
            external: true
        }
    }))).sort((a, b) => {
        a = new Date(a.node.publishDate);
        b = new Date(b.node.publishDate);
        return a > b ? -1 : a < b ? 1 : 0;
    });

    const { featuredPosts } = blogLanding;

    const isFirst = currentPage === 1
    const isLast = currentPage === numPages
    const prevPage = currentPage - 1 === 1 ? "/" : (currentPage - 1).toString()
    const nextPage = (currentPage + 1).toString()

    return (
        <>
            <SEO
                title={blogLanding.title}
                description={blogLanding.description}
                image={(data.blogLanding.openGraphImage && data.blogLanding.openGraphImage[0].src.childImageSharp.fixed.src) || null}
                slug="/blog"
            />
            <PageHero
                title={blogLanding.title}
                images={blogLanding.heroImages}
                description={blogLanding.metaDescription}
            />            
            <PageWrapper>
                { isFirst &&
                    <div>
                        <SubHeader>
                            Featured Posts
                        </SubHeader>
                        <FeaturedPosts>
                            {
                                featuredPosts.map((a, i) =>
                                <FeaturedPostWrapper>
                                    <Card
                                        title={a.title}
                                        image={a.heroImages[0]}
                                        publishDate={a.publishDate}
                                        slug={a.slug}
                                        imageDimensions={{ height: 400, width: 896 }}
                                    >
                                        { a.metaDescription && <PostDescription>
                                            { a.metaDescription}
                                        </PostDescription> }
                                    </Card>
                                </FeaturedPostWrapper>
                                )
                            }
                        </FeaturedPosts>
                    </div>
                }
                <SubHeader>
                    Latests Posts { !isFirst && `(${currentPage}/${numPages})` }
                </SubHeader>
                <div className="p-blogLanding__latestPosts">
                    {
                        allPosts.map(({ node: a }) =>
                            (<PostWrapper>
                                <ListingItem
                                    title={a.title}
                                    image={a.heroImages[0]}
                                    publishDate={a.publishDate}
                                    slug={a.slug}
                                    source={a.source}
                                    external={a.external}
                                >
                                    <PostDescription>
                                        { a.metaDescription}
                                    </PostDescription>
                                </ListingItem>
                            </PostWrapper>)
                        )
                    }
                </div>
            <div>
            <LandingWrapper>
                <Pagination
                    isFirst={isFirst}
                    isLast={isLast}
                    nextPage={nextPage}
                    prevPage={prevPage}
                    numPages={numPages}
                />
            </LandingWrapper>
        </div>
    </PageWrapper>
</>        
    )
};

export const pageQuery = graphql`
    query blogLandingQuery($skip: Int!, $limit: Int!) {
        blogLanding: jsonJson(id: {eq: "blog_page"}) {
            id
            title
            metaDescription
            heroImages {
                name
                src {
                    childImageSharp {
                        fixed(width: 700, height: 350, cropFocus: NORTH) {
                            ...GatsbyImageSharpFixed
                        }  
                        fluid(quality: 90, maxWidth: 1920, maxHeight: 350) {
                            ...GatsbyImageSharpFluid_withWebp_tracedSVG
                        }
                        fluidThumb: fluid(quality: 90, maxWidth: 1920, maxHeight: 1080, cropFocus: NORTH) {
                            ...GatsbyImageSharpFluid_withWebp
                        }               
                    }   
                }
            }
            openGraphImage: heroImages {
                name
                src {
                    childImageSharp {
                        fixed(width: 1200, height: 630) {
                            src
                        }
                    }
                }
            }
            featuredPosts {
                id
                title
                metaDescription
                slug
                heroImages {
                    name
                    src {
                        childImageSharp {
                            fixed(width: 700, height: 350, cropFocus: NORTH) {
                                ...GatsbyImageSharpFixed
                            }  
                            fluid(maxWidth: 700, maxHeight: 350, cropFocus: NORTH) {
                                ...GatsbyImageSharpFluid_noBase64
                            }              
                        }   
                    }
                }
                publishDate
                author: _author {
                    name
                }
            }
        }
        latestPosts: allPostsJson(
            limit: $limit,
            skip: $skip,
            sort: {order: DESC, fields: publishDate}
        ) {
            edges {
                node {
                    id
                    title
                    metaDescription
                    slug
                    heroImages {
                        name
                        src {
                            childImageSharp {
                                fluid(maxWidth: 164, maxHeight: 164, cropFocus: NORTH) {
                                    ...GatsbyImageSharpFluid_noBase64
                                }            
                            }   
                        }
                    }
                    publishDate
                    author: _author {
                        name
                    }
                }
            }
        }
        mediumFeed: allMediumFeed {
            edges {
                node {
                    author
                    content
                    date
                    link
                    slug
                    title
                    thumbnail
                    id
                }
            }
        }
    }`;

export default Blog;