import styled from "styled-components";
import Img from "gatsby-image";
import { headerFontMixin, articleFont, transitionColor, transitionBorder } from "../../styles/_mixins";
import { fluidFontSizes } from '../../styles/_calculations';
import {
    textPrimary,
    borderRadius,
    bpMobilePhablet,
    textSecondary
} from "../../styles/_theme-variables";

export const Root = styled.div`
    width: 100%;
    display: flex;
    ${fluidFontSizes.smallPrintSize}

    @media screen and (min-width: ${bpMobilePhablet}) {
        flex-direction: column;
    }

    @media screen and (max-width: ${bpMobilePhablet}) {
        flex-direction: row;
    }

    @media screen and (max-width: 30em) {
        flex-direction: column;
    }

    & a {
        text-decoration: none;
        color: ${textPrimary};
        ${transitionColor};
    }
`;

export const ImageWrapper = styled.div`
    width: 100%;
    border-radius: ${borderRadius};
    overflow: hidden;
    ${fluidFontSizes.smallPrintSize}
    margin-bottom: 0.5em;

    @media screen and (min-width: 30em) and (max-width: ${bpMobilePhablet}) {
        max-width: 8rem;
        margin-right: 0.5em;
        display: inline-table;
    }

    @media screen and (max-width: 30em) {
        margin-bottom: 0.5em;
    }

    border: 1px solid ${textPrimary};
    ${transitionBorder}
`;

export const HeaderText = styled.h3`
    ${headerFontMixin}
    ${fluidFontSizes.header6Size}
    font-weight: 400;
    margin-bottom: 0.5em;
    line-height: 1.2;
    ${transitionColor};

    a:hover & {
        color: ${textSecondary};
    }
`;

export const PublishDate = styled.p`
    ${articleFont}
    ${fluidFontSizes.smallPrintSize}
    ${transitionColor};

    a:hover & {
        color: ${textSecondary};
    }
`;

export const Image = styled(Img)`
    width: 100%;   
    display: block;   
`;
