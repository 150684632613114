import React from "react";
import { Link } from 'gatsby';

import {
    Root,
    ImageWrapper,
    Content,
    StyledImg as Img,
    Header,
    PublishDate,
    CTA,
    ExternalCTA
} from "./listing-item-styles";

const mapImage = ({ src, name }) => (
    <Img
        fluid={src.childImageSharp.fluid}            
        alt={name}
    />
);

const mapExternalImage = ({src, name }) => (
    <img src={src} alt={name} />
);

export interface ListingItemProps {
    title: string;
    image: any;
    publishDate: string;
    slug: string;
    children: any;
    source?: string;
    external?: boolean;
}

const ListingItem = (props: ListingItemProps) => (
    <Root>
        { !props.external && <ImageWrapper>
                { props.image.src && mapImage(props.image) }
            </ImageWrapper>
        }
        {
            props.external && <ImageWrapper>
                { props.image.src && mapExternalImage(props.image) }
            </ImageWrapper>
        }
        <Content>
            { !props.external && <Link to={`blog/${props.slug}`}>
                <Header>{ props.title }</Header>            
                <PublishDate>
                    { new Date(props.publishDate).toDateString() }
                </PublishDate>
            </Link> }
            { props.external && <a href={props.slug} target="_blank">
                <Header>{ props.title } - ({props.source})</Header>            
                <PublishDate>
                    { new Date(props.publishDate).toDateString() }
                </PublishDate>
            </a> }
            { props.children }
            { !props.external && <CTA to={`blog/${props.slug}`}>read more...</CTA> }
            { props.external && <ExternalCTA href={props.slug} target="_blank">read more on {props.source}...</ExternalCTA> }
        </Content>
    </Root>
);

export default ListingItem;
