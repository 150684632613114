import React from "react";
import { Link } from 'gatsby';

import {
    Root,
    ImageWrapper,
    Image,
    HeaderText,
    PublishDate
} from "./styled";

const mapImage = ({ src, name }) => (
    <Image
        className="c-card__image"
        fluid={src.childImageSharp.fluid}            
        alt={name}
    />
);

const Card = ({ title, image, publishDate, slug, children}) => (
    <Root className="c-card__root">
        <ImageWrapper className="c-card__imageWrapper">
            { mapImage(image) }
        </ImageWrapper>
        <Link to={`blog/${slug}`}>
            <HeaderText className="c-card__header">{ title }</HeaderText>            
            <PublishDate className="c-card__publishDate">
                { new Date(publishDate).toDateString() }
            </PublishDate>
            { children }
        </Link>
    </Root>
);

export default Card;