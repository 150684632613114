import styled from "styled-components";
import Img from "gatsby-image";
import { Link } from "gatsby";

import {
    borderRadius,
    textPrimary,
    textSecondary
} from "../../../styles/_theme-variables";
import { articleFont, headerFontMixin } from '../../../styles/_mixins';
import { fluidFontSizes } from '../../../styles/_calculations';

const ctaColor = textSecondary;

export const Root = styled.div`
    display: flex;

    @media screen and (min-width: 30em)  {
        flex-direction: row;
    }

    @media screen and (max-width: 30em) {
        flex-direction: column;
    }
`;

export const ImageWrapper = styled.div`
    border-radius: ${borderRadius};
    overflow: hidden;
    margin-bottom: 0.5em;

    @media screen and (min-width: 30em)  {
        flex-basis: 15%;
    }
`;

export const Content = styled.div`
    flex: 1;
    ${fluidFontSizes.smallPrintSize}

    @media screen and (min-width: 30em)  {
        padding-left: 1em;
    }

    & a {
        color: ${ctaColor};
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }
`;

export const StyledImg = styled(Img)`
    width: 100%;
    display: block;
    ${fluidFontSizes.bodySize}

    border-radius: ${borderRadius};
    overflow: hidden;

    @media screen and (max-width: 30em) {
        max-height: 140px;
        object-fit: cover;
        object-position: center;
    }    
`;

export const Header = styled.h3`
    ${headerFontMixin}
    ${fluidFontSizes.header6Size}
    font-weight: 400;
    margin-bottom: 0.5em;
    line-height: 1.1;    
`;

export const PublishDate = styled.p`
    ${articleFont};
    ${fluidFontSizes.smallPrintSize}
    margin-bottom: 0.5em;
`;

export const CTA = styled(Link)`
    margin: 0.5em 0;
    display: block;
    ${fluidFontSizes.smallPrintSize}
    color: ${ctaColor};
`;

export const ExternalCTA = styled.a`
    margin: 0.5em 0;
    display: block;
    ${fluidFontSizes.smallPrintSize}
    color: ${ctaColor};
`;