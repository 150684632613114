import styled from "styled-components";
import { Link } from "gatsby";

import { borderRadius, backgroundPrimary, textSecondary } from "../../styles/_theme-variables";
import { articleFont, transitionTime } from "../../styles/_mixins";
import { fluidFontSizes } from "../../styles/_calculations";

export const Root = styled.div`
    ${fluidFontSizes.bodySize}
    padding: 0.5em;
`;

export const Pages = styled.ul`
    display: flex;
    list-style: none;

    @media screen and (min-width: 50em) {      
        flex-direction: row;
    }

    @media screen and (max-width: 50em) {      
        flex-direction: column;
    }
`;

export const Item = styled.li`
    display: inline-block;
    margin: 1rem;
`;

export const GatsbyLink = styled(Link)`
    display: inline;
    ${articleFont};
    ${fluidFontSizes.bodySize}
    padding: 0.5em;      
    color: ${textSecondary}; 
    text-decoration: none;
    border-radius: ${borderRadius};
    border: 1px solid ${textSecondary};
    transition:
        border ${transitionTime} ease-in,
        background-color ${transitionTime} ease-in,
        color ${transitionTime} ease-in;

    &:hover {
        background-color: ${textSecondary};
        color: ${backgroundPrimary};
    }

    @media screen and (max-width: 50em) {
        width: 100%;
        display: inline-block;
        text-align: center;
    }
`;