import React from "react";

import { Root, Item, Pages, GatsbyLink as Link } from "./styled";

const Pagination = ({
    isFirst,
    isLast,
    nextPage,
    prevPage,
    numPages
}) => (
    <Root>
        <Pages>
            {!isFirst && (
                <Item>
                    <Link
                        to={`blog/${prevPage}`}
                        rel="prev"
                    >
                        ← Previous Page
                    </Link>
                </Item>
            )}

            {Array.from({ length: numPages }, (_, i) => (
                <Item>
                    <Link
                        key={`pagination-number${i + 1}`}
                        to={`/blog/${i === 0 ? "" : i + 1}`}
                    >
                        {i + 1}
                    </Link>
                </Item>
            ))}

            {!isLast && (
                <Item>
                    <Link
                        to={`blog/${nextPage}`}
                        rel="next"
                    >
                        Next Page →
                    </Link>
                </Item>
            )}
        </Pages>
    </Root>
);

export default Pagination;
