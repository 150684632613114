import styled, { css } from "styled-components";

import { articleFont, transitionColor, transitionBorder } from "../../styles/_mixins";
import { fluidFontSizes } from '../../styles/_calculations';

import {
    bpMobilePhablet,
    headerFont,
    subHeaderSize,
    mobileSubHeaderSize,
    textPrimary,
    textSecondary
} from "../../styles/_theme-variables";

const headerColor = textPrimary;
const borderColor = textSecondary;

export const FeaturedPosts = styled.div`
    display: flex;
    flex-wrap: wrap;
    ${fluidFontSizes.smallPrintSize}
    margin-bottom: 1em;
    border-bottom: 1px solid ${borderColor};
    ${transitionBorder}

    @media screen and (max-width: ${bpMobilePhablet}) {
        flex-direction: column;
    }
`;

const FeaturedPostWrapperStyles = css`
    ${fluidFontSizes.smallPrintSize}

    @media screen and (min-width: ${bpMobilePhablet}) {
        flex-basis: 33%;
        padding: 1em;
    }

    @media screen and (max-width: ${bpMobilePhablet}) {
        flex: 1;
        margin-bottom: 1em;
    }
`;

export const FeaturedPostWrapper = styled.div`
    ${FeaturedPostWrapperStyles}
`;

export const PostDescription = styled.p`
    ${fluidFontSizes.smallPrintSize}
    color: ${headerColor};
    ${articleFont};
    margin-bottom: 0.5em;
    padding: 0;
`;

export const SubHeader = styled.h3`
    color: ${headerColor};
    ${transitionColor};
    ${fluidFontSizes.header6Size}
    margin-bottom: 0.5em;
    font-weight: 400;

    line-height: 1.3;
    font-family: ${headerFont};

    @media screen and (min-width: ${bpMobilePhablet}) {
        font-size: ${subHeaderSize};
    }

    @media screen and (max-width: ${bpMobilePhablet}) {
        font-size: ${mobileSubHeaderSize};
    }
`;

export const PostWrapper = styled.div`
    ${fluidFontSizes.bodySize}
    margin-bottom: 3em;
    max-width: 55rem;
`;

export const LandingWrapper = styled.div`
    display: flex;
    justify-content: center;
`;
